import "../style/signin.css";
import Navbar from "./Navbar";
import phoneIcon from "../images/phone-icon.png";
import googleIcon from "../images/google-icon.png";

export function SignIn() {
	return (
		<div>
			<Navbar />
			<div className="sign-in">
				<div className="sign-in-container">
					<div className="page-title">Login</div>
					{/* email & password */}
					<div className="">
						<input
							type="text"
							placeholder="Email"
							style={{
								marginBottom: "15px",
							}}
						/>
						<input
							type="password"
							placeholder="Password"
							style={{
								marginBottom: "15px",
							}}
						/>
						<div
							className="submit-button"
							style={{
								marginBottom: "15px",
							}}
						>
							Login
						</div>
					</div>
					<div className="options-container">
						{/* signin with phone */}
						<div className="button google-button">
							<img
								className="icon-img"
								src={googleIcon}
								alt="google icon"
							/>
							Google
						</div>

						{/* signin with google */}
						<div className="button phone-button">
							<img
								className="icon-img"
								src={phoneIcon}
								alt="phone icon"
							/>
							Phone
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
