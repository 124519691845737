import React, { useEffect } from 'react';
import { getAuth, signOut } from "firebase/auth";
import { useState } from 'react';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import SneacoLogo from '../images/Sneacologo2.svg';

export default function Navbar() {
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"));
    }
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    const dummy = signOut(auth).then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('uid');
      localStorage.removeItem('vendorId');
      localStorage.removeItem('laundryId');
      setToken(null);
      navigate("/");
      alert("You are successfully logged out!");
    });
    return dummy;
  };

  return (
    <BootstrapNavbar expand="lg" style={{ padding: 0 }}>
      <div className="container-fluid">
        <Link className="navbar-brand logo" to="/">
          <img src={SneacoLogo} alt="Sneaco Logo" />
        </Link>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav" style={{ marginRight: '24px' }}>
          <Nav className="ms-auto align-items-center">
            {token && (
              <Link className="nav-link mx-3 link" to="/myorders">
                My orders
              </Link>
            )}
            {token && (
              <Link className="nav-link mx-3 link" to="/profile">
                Profile
              </Link>
            )}
          </Nav>
          <div className="d-flex align-items-center justify-content-center">
            {!token ? (
              <>
               <a
  className="btn text-white border-white pt-2 mx-3 link button-size"
  href="https://play.google.com/store/search?q=sneaco&c=apps"
  target="_blank"
  rel="noopener noreferrer"
>
  Get the App
</a>
                <Link className="btn bg-white text-success pt-2 mx-3 link button-size" to="/signup">
                  Sign in
                </Link>
              </>
            ) : (
              <Link className="btn bg-white text-danger mx-3 link" onClick={handleLogout}>
                Log out
              </Link>
            )}
          </div>
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  );
}
