import "../style/signup.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	RecaptchaVerifier,
	signInWithPhoneNumber,
	signInWithPopup,
	signOut,
} from "firebase/auth";
import { auth, googleProvider } from "../config/firebase-config";
import Navbar from "./Navbar";
import axios from "axios";
import phoneIcon from "../images/phone-icon.png";
import googleIcon from "../images/google-icon.png";

export function SignUp() {
	const navigate = useNavigate();
	const [timeoutId, setTimeoutId] = useState(null);

	useEffect(() => {
		const user = localStorage.getItem("token");
		if (user) {
			navigate("/profile");
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, [navigate, timeoutId]);

	const [contact, setContact] = useState("");
	const [change, setChange] = useState(false);
	const [otp, setOtp] = useState("");

	const sendOTP = async () => {
		try {
			const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
				size: "invisible",
			});
			const confirmation = await signInWithPhoneNumber(
				auth,
				contact,
				recaptcha
			);
			window.confirmation = confirmation;
			setChange(true);
			setTimeout(() => {
				window.open("/", "_self");
			}, 30000);
		} catch (error) {
			toast.error("Please provide valid credentials");
		}
	};

	const verifyOTP = async () => {
		try {
			clearTimeout(timeoutId);
			const data = await window.confirmation.confirm(otp);
			console.log(data);

			axios
				.post(
					`${process.env.REACT_APP_BACKEND_DOMAIN}/api/auth/sign-up/phone`,
					{ contact: data.user.phoneNumber, userId: data.user.uid }
				)
				.then((response) => {
					navigate("/profile");
					toast.success(response.data.message);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status === 400) {
							toast.error(error.response.data.message);
						} else if (error.response.status === 500) {
							toast.error(error.response.data.message);
						} else {
							toast.error(error.response.data.message);
						}
					} else if (error.request) {
						toast.error(
							"No response received. Please check your network connection."
						);
					} else {
						toast.error(
							"An error occurred. Please try again later."
						);
					}
					signOut(auth).then(() => {
						localStorage.removeItem("token");
						localStorage.removeItem("uid");
					});
				});
		} catch (error) {
			toast.error("Invalid OTP !");
		}
	};

	const handleGoogleAuthClick = async () => {
		await signInWithPopup(auth, googleProvider)
			.then((data) => {
				console.log(data);
				axios
					.post(
						`${process.env.REACT_APP_BACKEND_DOMAIN}/api/auth/sign-up/google`,
						{
							email: data.user.email,
							name: data.user.displayName,
							userId: data.user.uid,
						}
					)
					.then((response) => {
						navigate("/profile");
						toast.success(response.data.message);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.status === 400) {
								toast.error(error.response.data.message);
							} else if (error.response.status === 500) {
								toast.error(error.response.data.message);
							} else {
								toast.error(error.response.data.message);
							}
						} else if (error.request) {
							console.log(error.request, 3);
							toast.error(
								"No response received. Please check your network connection."
							);
						} else {
							console.log("Error", error.message, 4);
							toast.error(
								"An error occurred. Please try again later."
							);
						}
						signOut(auth).then(() => {
							localStorage.removeItem("token");
							localStorage.removeItem("uid");
						});
					});
			})
			.catch((error) => {
				console.log(error, 5);
				toast.error(
					"An unexpected error occurred. Please try again later."
				);
			});
	};
	return (
		<div>
			<Navbar />
			<div className="sign-up">
				<div className="sign-up-container">
					<div className="page-title">Sign Up</div>
					<div>
						<input
							type="text"
							placeholder="Full Name"
							style={{
								marginBottom: "15px",
							}}
						/>
						<input
							type="text"
							placeholder="Email"
							style={{
								marginBottom: "15px",
							}}
						/>
						<input
							type="password"
							placeholder="Password"
							style={{
								marginBottom: "15px",
							}}
						/>
						<input
							type="password"
							placeholder="Confirm Password"
							style={{
								marginBottom: "15px",
							}}
						/>
						<div
							className="submit-button"
							style={{
								marginBottom: "15px",
							}}
						>
							Sign Up
						</div>
					</div>
					<div className="options-container">
						{/* signin with phone */}
						<div
							className="button google-button"
							onClick={handleGoogleAuthClick}
						>
							<img
								className="icon-img"
								src={googleIcon}
								alt="google icon"
							/>
							Google
						</div>

						{/* signin with google */}
						<div className="button phone-button">
							<img
								className="icon-img"
								src={phoneIcon}
								alt="phone icon"
							/>
							Phone
						</div>
					</div>
					{/* {change ? (
						<div className="input-group">
							<input
								type="number"
								placeholder="Enter OTP"
								value={otp}
								onChange={(e) => setOtp(e.target.value)}
							/>
							<div className="submit-button" onClick={verifyOTP}>
								Submit
							</div>
						</div>
					) : (
						<div className="phone-input-container">
							<div
								className="submit-button"
								onClick={sendOTP}
								style={{
									marginBottom: "30px",
									padding: "10px",
									border: "1px solid #ccc",
									borderRadius: "5px",
								}}
							>
								Get OTP
							</div>
							<div id="recaptcha"></div>
						</div>
					)} */}
				</div>
				<ToastContainer />
			</div>
		</div>
	);
}
